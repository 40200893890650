
import '../styles/globals.css'
import Layout, { Providers } from '../containers/shared/Layout'
import LoginRegister from '../containers/shared/Layout/LoginRegister' // todo could consider dynamic import here
import Index from './index'
import { useContext, useEffect } from 'react'

import { CurrentUserContext } from '../contexts/CurrentUserContext'
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../mocks')
}

const DerpLayout = ({ Component, pageProps }) => {
  useEffect(() => {
    // remove default number input type scroll behavior
    // from https://stackoverflow.com/a/38589039
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, [])


  console.log('comp', Component)
  // we check this flag in the apiMiddleware.js file as well
  const skipAuth = process.env.NEXT_PUBLIC_SKIP_AUTH === 'true'
  if (skipAuth) {
    console.log("Layout skipping auth")
  }

  const currentUserContext = useContext(CurrentUserContext)
  const skipLayout = pageProps?.skipLayout || !currentUserContext.isSignedIn
  const innards = currentUserContext.isSignedIn || skipAuth
    ? <Component {...pageProps} />
    : <LoginRegister />

  if (skipLayout) {
    return innards
  } else {
    return <Layout>{innards}</Layout>
  }
}

function MyApp({ Component, pageProps }) {
  // Use the layout defined at the page level, if available
  const layoutOverride = Component.getLayout

  if (layoutOverride) {
    return <Providers>{layoutOverride(<Component {...pageProps} />)}</Providers>
  } else {
    return <Providers><DerpLayout Component={Component} pageProps={pageProps} /></Providers>
  }
}

MyApp.getInitialProps = async (ctx) => {

  const { pathname } = ctx.ctx
  const skipLayout = pathname === '/purchaseOrder/pdf/[purchaseOrderNumber]'

  // todo, need to set this up for the PDF page
  // process.env.PDF_SECURITY_TOKEN
  return {
    pageProps: { skipLayout }
  }
}

export default MyApp
