import React, { createContext, useState, useContext } from 'react'

export const FetchContext = createContext({})
export const FetchContextProvider = ({children}) => {
    const [numberOfRequests, setNumberOfRequests] = useState(0)
    // todo tie in auth here?

    return (
        <FetchContext.Provider value={{isLoading: numberOfRequests !== 0, numberOfRequests, setNumberOfRequests }}>
            {children}
        </FetchContext.Provider>
    )
}
