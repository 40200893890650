export const addUrlParameters = (url, parameters) => {

    // this builds a url like...
    // www.derp.whatever?key1=value1&key2=value2&key3=value3

    const newQuery = Object.entries(parameters || {}).map(([k,v]) => `${k}=${v}`).join('&')

    if(!newQuery){
        return url
    }

    const separator = url.includes('?') ? '&' : '?'
    const newUrl = `${url}${separator}${newQuery}`
    
    return newUrl
}
