import React, { createContext, useEffect, useState } from 'react'
import useFetch from "../../utils/useFetch";
import { getAuth, updatePassword as updatePasswordFirebase, updateProfile, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import firebaseApp from "../../utils/firebaseClientApp";

function readCookie(name) {

    const cookieArray = document.cookie.split('; ');
    const cookieObject = cookieArray.reduce((acc, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = value;
        return acc;
    }, {});

    return cookieObject[name];
}

function setCookie(name, value, maxAge) {
    document.cookie = `${name}=${value}; path=/; max-age=${maxAge};`
}

const ORGANIZATION_COOKIE_KEY = "organizationId"

export const CurrentUserContext = createContext({})
export const CurrentUserConsumer = CurrentUserContext.Consumer

const useUser = () => {
    const [user, setUser] = useState()
    useEffect(() => {
        const auth = getAuth(firebaseApp)
        const unregisterAuthObserver = auth.onAuthStateChanged(user => {
            setUser(user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    return user;
}

export const CurrentUserProvider = ({ children }) => {

    const user = useUser();
    const [currentUserData, setCurrentUserData] = useState();
    const { get } = useFetch();
    const [organizationId, setOrganizationId] = useState()

    useEffect(() => {
        if (user && !process.env.NODE_ENV.includes("test")) {
            // since session will change and fire this after the test finishes
            // we're doing this to avoid this error
            // undefined Cannot read properties of null (reading '_location')
            // which is hell to fix
            get(`/api/currentUserData`).then((userData) => {
                setCurrentUserData(userData, false);
            });
        }
    }, [user, organizationId]);



    const setOrganization = (organizationId, reloadPage = true) => {
        organizationId = organizationId || ''
        setOrganizationId(organizationId)
        setCookie(ORGANIZATION_COOKIE_KEY, organizationId, 31536000)
        if (reloadPage) {
            window.location.reload()
        }
    }

    useEffect(() => {
        // if no org selected, pick the first one...
        const orgIdCookie = readCookie(ORGANIZATION_COOKIE_KEY)
        if (!orgIdCookie) {
            const firstOrg = userOrganizations[0]?.organizationId
            if (firstOrg)
                setOrganization(firstOrg, false)
        } else {
            // refresh the cookie and set the state up
            setOrganization(orgIdCookie, false)
        }
    }, [currentUserData])

    const permissions = currentUserData?.permissions ?? {}
    const userOrganizations = currentUserData?.organizations ?? []
    const currentOrganization = userOrganizations.find(org => org.organizationId === organizationId) ?? {}

    const getFirebaseAuth = () => getAuth(firebaseApp)
    const signOut = () => {
        getFirebaseAuth().signOut()
    }
    const updatePassword = async (oldPassword, newPassword) => {
        const credential = EmailAuthProvider.credential(user.email, oldPassword)
        const reauth = await reauthenticateWithCredential(user, credential)
        console.log({ reauth })
        if (reauth.user) {
            await updatePasswordFirebase(user, newPassword)
        }
    }

    const updateDisplayNameFirebase = async (newName) => {
        const auth = getAuth();
        const updatedProfile = await updateProfile(auth.currentUser, {
            displayName: newName
        });
    };

    const updateEmailFirebase = async (newEmail, credential) => {
        const auth = getAuth();
        const user = auth.currentUser;

        const reauth = await reauthenticateWithCredential(user, credential);
        console.log({ reauth })
        if (reauth.user) {
            await updateEmail(auth.currentUser, newEmail);
        }
    };

    console.log({ user })

    return (
        <CurrentUserContext.Provider value={{
            permissions,
            userOrganizations,
            setOrganization,
            currentOrganization,
            signOut,
            isSignedIn: !!user,
            updatePassword,
            email: user?.email,
            displayName: user?.displayName,
            updateDisplayNameFirebase,
            updateEmailFirebase
        }}>
            {children}
        </CurrentUserContext.Provider>
    )
}
