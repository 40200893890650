import { useEffect, useRef, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

// borrowed from https://github.com/firebase/firebaseui-web-react/pull/173#issuecomment-1215648239
const StyledFirebaseAuth = ({ uiConfig, firebaseAuth, className, uiCallback }) => {
    const [firebaseui, setFirebaseui] = useState(null);
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);

    const importFirebaseLibraries = async () => {
        const [_, firebaseui] = await Promise.all([
            import('firebaseui/dist/firebaseui.css'),
            import('firebaseui')
        ])
        return firebaseui
    }

    useEffect(() => {
        // Firebase UI only works on the Client. So we're loading the package only after
        // the component has mounted, so that this works when doing server-side rendering.
        importFirebaseLibraries().then(firebaseUI => setFirebaseui(firebaseUI))
    }, []);


    useEffect(() => {
        if (firebaseui === null)
            return;

        // Get or Create a firebaseUI instance.
        const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
        if (uiConfig.signInFlow === 'popup')
            firebaseUiWidget.reset();

        // We track the auth state to reset firebaseUi if the user signs out.
        const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, user => {
            if (!user && userSignedIn)
                firebaseUiWidget.reset();
            setUserSignedIn(!!user);
        });

        // Trigger the callback if any was set.
        if (uiCallback)
            uiCallback(firebaseUiWidget);

        // Render the firebaseUi Widget.
        // @ts-ignore
        firebaseUiWidget.start(elementRef.current, uiConfig);

        return () => {
            unregisterAuthObserver();
            firebaseUiWidget.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseui, uiConfig]);

    return <div className={className} ref={elementRef} />;
};

export default StyledFirebaseAuth;