import { useState, useEffect } from 'react'
import { resolve } from 'styled-jsx/css'

const useDebounce = (setResult, onComplete) => {
    const [promises, setPromises] = useState([])

    useEffect(() => {
        // just waits for all the promises and takes the last one
        if(promises.length){
            Promise.allSettled(promises).then(res => {
                // this allows for timeouts/failed promises (it just filters them out from the valid results)
                const fulfilledResults = res.filter(x => !!x.value).map(x => x.value)
                if(fulfilledResults.length){
                    const resultToUse = fulfilledResults[fulfilledResults.length-1]
                    setResult(resultToUse)
                }
            }).finally(() => {
                setPromises([])
                if(onComplete){
                    onComplete()
                }
            })
        }
    }, [promises])

    const debounce = (promise) => {
        setPromises(promises => [...promises, promise])
    }

    return debounce
}

export default useDebounce    