import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'
import dynamic from 'next/dynamic'

const Test = dynamic(() => import('../containers/shared/Test/Test').then(x => x.Test))
const Test2 = dynamic(() => import('../containers/shared/Test/Test').then(x => x.Test2), { ssr: false })

export default function Home() {
  return (
    <div className={styles.container}>
      <Head>
        <title>Dogs Enterprise Resource Planning</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <h1 className={styles.title}>
          Welcome to <a href="/">DERP!</a>
        </h1>

        <p className={styles.description}>
          Get started by DERPin
        </p>

          
      </main>

      <footer className={styles.footer}>
        <Test/>
        <Test2/>
      </footer>
    </div>
  )
}
