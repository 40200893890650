import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SearchField from '../SearchField/SearchField';
import useFetch from '../../utils/useFetch';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import NextLink from 'next/link'
import useDebounce from '../../utils/useDebounce';
const SearchResultTable = ({ items, idPropertyName, columnDefinitions, title, onRowClick }) => {
  return (
    items?.length > 0 && (
      <>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: '600' }}>{title}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => {
            if(!item.detailsUrl){
              console.error('no details url found on', item)
            }
            return <TableRow key={item[idPropertyName]}>
              {columnDefinitions.map((col) => {

                return <TableCell key={item[idPropertyName] + item[col.propertyName]}>
                  <NextLink href={item.detailsUrl} legacyBehavior>
                    <a onClick={onRowClick}>{item[col.propertyName]}</a>
                  </NextLink>
                </TableCell>
              })}
            </TableRow>
          })}
        </TableBody>
      </>
    )
  );
};

const SearchModal = ({ open, handleClose }) => {
  const { get } = useFetch();
  const [searchResult, setSearchResult] = useState({});
  const [input, setInput] = useState("");
  const debounce = useDebounce(setSearchResult)

  useEffect(() => {
    if (!open) {
      setInput("")
    }
  }, [open])

  useEffect(() => {
    const requestUrl = `/api/search?query=${input}`;

    if (input) {
      debounce(get(requestUrl))
    } else {
      setSearchResult({})
    }
  }, [input]);

  const modalContents = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'white',
    boxShadow: 16,
    p: 4,
    borderRadius: 2,
    height: '80vh',
    width: '80vw',
    overflowY: 'auto',
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Grid alignItems="center" sx={modalContents}>
        <SearchField autoFocus sx={{ width: '50%' }} placeholder="Search..." value={input} onChange={(e) => setInput(e.target.value)} />
        <Grid container>
          <Box sx={{ width: '100vw' }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <SearchResultTable
                  items={searchResult.vendors}
                  title="Vendors"
                  idPropertyName="vendorCode"
                  columnDefinitions={[
                    { propertyName: 'vendorCode' },
                    { propertyName: 'vendorName' },
                  ]}
                />

                <SearchResultTable
                  items={searchResult.brands}
                  title="Brands"
                  idPropertyName="brandCode"
                  columnDefinitions={[{ propertyName: 'brandName' }, { propertyName: 'brandCode' }]}
                  onRowClick={handleClose}
                />
                <SearchResultTable
                  items={searchResult.products}
                  title="Products"
                  idPropertyName="sku"
                  columnDefinitions={[{ propertyName: 'sku' }, { propertyName: 'title' }, { propertyName: 'price' }]}
                  onRowClick={handleClose}

                />
                <SearchResultTable
                  items={searchResult.orders}
                  title="Orders"
                  idPropertyName="orderNumber"
                  columnDefinitions={[
                    { propertyName: 'referenceNumber' },
                    { propertyName: 'orderStatus' },
                    { propertyName: 'orderDate' },
                    { propertyName: 'customerName' },
                    { propertyName: 'customerEmail' },
                  ]}
                  onRowClick={handleClose}

                />
                <SearchResultTable
                  items={searchResult.purchaseOrders}
                  title="Purchase Orders"
                  idPropertyName="purchaseOrderNumber"
                  columnDefinitions={[
                    { propertyName: 'purchaseOrderNumber' },
                    { propertyName: 'purchaseOrderStatus' },
                    { propertyName: 'purchaseOrderDate' },
                    { propertyName: 'vendorName' },
                  ]}
                  onRowClick={handleClose}

                />
                <SearchResultTable
                  items={searchResult.customers}
                  title="Customers"
                  idPropertyName="customerId"
                  columnDefinitions={[
                    { propertyName: 'customerName' },
                    { propertyName: 'email' },
                  ]}
                  onRowClick={handleClose}
                />


                <TableBody>
                  {/* {items?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {props.columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })} */}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {items && ( */}
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={totalItems}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            {/* )} */}
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SearchModal;
