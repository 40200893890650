import React, { createContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const ConfirmContext = createContext({})
export const Confirm = ({ children }) => {

    const [message, setMessage] = useState("")
    const [title, setTitle] = useState("")
    const [open, setOpen] = useState(false)

    const confirmModal = async (title, message) => {
        setTitle(title)
        setMessage(message)
        setOpen(true)

        return new Promise((resolve => {
            document.addEventListener("DERP.HandleConfirm", e => {
                setOpen(false)
                console.log(e)
                resolve(e.detail.confirmResult)
            }, { once: true});
        }))
    }

    const sendEvent = confirmResult => document.dispatchEvent(new CustomEvent("DERP.HandleConfirm", {
        detail: {
            confirmResult
        }
    }))
    const handleNo = () => sendEvent(false)
    const handleYes = () => sendEvent(true)

    return (
        <ConfirmContext.Provider value={{ confirmModal }}>
            {children}

            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNo}>No</Button>
                    <Button onClick={handleYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </ConfirmContext.Provider>
    )
}


