import React, { createContext, useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Alert from './Alert'

export const SnackContext = createContext({})
export const Snacks = ({children}) => {

    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("success")
    const [open, setOpen] = useState(false)

    const sendSnack = (message, severity = "success") => {
        setMessage(message)
        setSeverity(severity)
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    return (
        <SnackContext.Provider value={{sendSnack}}>
            {children}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </SnackContext.Provider>
    )
}
