import { getAuth } from 'firebase/auth';
import firebaseApp from "../firebaseClientApp";

export const fetchWrapperJson = async (url, method, body) => {
    return fetchWrapper(url, method, JSON.stringify(body), 'application/json')
}

export const fetchWrapperFormData = async (url, method, formData) => {
    return fetchWrapper(url, method, formData, undefined)
}

export const fetchWrapper = async (url, method, body, contentType) => {
    console.log('fetchWrapper', method, url, JSON.stringify(body));
    const firebaseIdToken = await getAuth(firebaseApp).currentUser?.getIdToken()
    const request = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + firebaseIdToken,
        },
    };
    if (body) {
        request.body = body;
        if (contentType) {
            request.headers['Content-Type'] = contentType;
        }
    }
    try {
        const response = await fetch(url, request);
        return response
    } catch (e) {
        console.error(e)
        throw `failed to call to ${method} ${url} ${JSON.stringify(body)} ${e.message}`
    }
}

