import { initializeApp } from "firebase/app";

// NOTE, none of this stuff is secret
// it is intended to be public and used on the client
// that's why we're ok with pushing it to the repo

const devFirebaseConfig = {
    apiKey: "AIzaSyBr0YJZh-hUSRhzYnxmF0RqmxYNOaYoW2s",
    authDomain: "derp-erp-dev.firebaseapp.com",
    projectId: "derp-erp-dev",
    storageBucket: "derp-erp-dev.appspot.com",
    messagingSenderId: "905257093775",
    appId: "1:905257093775:web:43d9f46e4a008e05a8befa"
};

const prodFirebaseConfig = {
    apiKey: "AIzaSyDQoNVhcgvnZEmu1AT5PUtKvE575wV31aE",
    authDomain: "derp-erp-production.firebaseapp.com",
    projectId: "derp-erp-production",
    storageBucket: "derp-erp-production.appspot.com",
    messagingSenderId: "836107951818",
    appId: "1:836107951818:web:c2823e33477d1f2dd69e33"
}

let publicFirebaseConfig;
if (process.env.NEXT_PUBLIC_ENVIRONMENT == 'production') {
    publicFirebaseConfig = prodFirebaseConfig
} else {
    publicFirebaseConfig = devFirebaseConfig
}

const app = initializeApp(publicFirebaseConfig);

export default app