import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const SearchField = (props) => {

const searchFieldStyle = {width: 400 };

  return (
    <TextField
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      margin="normal"
      sx={{ ...searchFieldStyle, ...props.sx }}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
