import React from 'react';
import StyledFirebaseAuth from './LoginRegister/StyledFirebaseAuth';
import firebaseApp from "../../../utils/firebaseClientApp";
import { getAuth } from 'firebase/auth';
import { EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import styles from '../../../styles/Home.module.css';
import Head from 'next/head';

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'redirect',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
        // GoogleAuthProvider.PROVIDER_ID, // will need to register with them and all that 
        // FacebookAuthProvider.PROVIDER_ID, // will need to register with them and all that
    ],
    callbacks: {
        signInSuccessWithAuthResult: () => {
            // do nothing, just let the layout handle it...
        }
    },
};

function LoginRegister() {
    return (
        <div className={styles.container}>
            <Head>
                <title>Dogs Enterprise Resource Planning</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main className={styles.main}>
                <h1 className={styles.title}>
                    Welcome to <a href="/">DERP!</a>
                </h1>

                <p className={styles.description}>
                    Get started by DERPin
                </p>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(firebaseApp)} />
                <style>
                    {`
                        .firebaseui-container {
                            width: 350px;
                        }
                    `
                    }
                </style>
            </main>

            <footer className={styles.footer}>
            </footer>
        </div>
    );
}

export default LoginRegister;